import 'reflect-metadata'
import Vue from 'vue'
import VueFirebase from './plugins/firebase'
import VueSentry from './plugins/sentry'
import VueMeta from './plugins/meta'
import VueFontAwesome from './plugins/fontawesome'
import VueValidation from './plugins/validation'
import VueFilters from "./plugins/filters"

import Datepicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
Vue.component('Datepicker', Datepicker);

import vuetify from "./plugins/vuetify"
import '@mdi/font/css/materialdesignicons.css'

import store from './store'
import getRouter from './router'

import esUI from '@es/ui'

import App from './App.vue'

import { Notifier } from '@airbrake/browser';

import './styles/index.scss'

Vue.config.productionTip = false

// ERROR Tracking STARTS
if (process.env.NODE_ENV === 'production') {
  const { AIRBRAKE_PROJECT_ID, AIRBRAKE_API_KEY } = process.env
  if (AIRBRAKE_PROJECT_ID && AIRBRAKE_API_KEY) {
    const airbrake = new Notifier({
      environment: 'production',
      projectId: Number(AIRBRAKE_PROJECT_ID),
      projectKey: AIRBRAKE_API_KEY
    });

    Vue.config.errorHandler = function (err, vm, info) {
      airbrake.notify({
        error: err,
        params: {info: info}
      });
    }
  }
}
// ERROR Tracking ENDS

Vue.use(VueMeta)
Vue.use(VueFontAwesome)
Vue.use(VueValidation)
Vue.use(VueFilters)

Vue.use(esUI, {
  platform: 'web'
})

// Initialise Vue on auth state check
Vue.use(VueFirebase, {
  store,
  onReady: () => {
    const authorisedRoles = store.getters['account/user']?.roles || []
    const router = getRouter(authorisedRoles)

    Vue.use(VueSentry, router)

    new Vue({
      vuetify,
      render: h => h(App),
      store,
      router,
      metaInfo: () => ({
        title: 'EnglishScore - CMS'
      }),
    }).$mount('#app')
  }
})