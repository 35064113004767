const getImageSize = url => new Promise((resolve, reject) => {
  const img = new Image()
    img.onload = () => {
      resolve({
        width: img.width,
        height: img.height,
      })
    }
    img.onerror = (err) => {
      reject(err)
    }
    img.src = url
})

export {
  getImageSize
}